import React, { useContext, useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FaSquarespace } from "react-icons/fa";
import { AiFillHome, AiFillDashboard } from "react-icons/ai";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  ListSubheader,
  makeStyles,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from "@material-ui/core";
import NavItem from "./NavItem";

import GavelIcon from "@material-ui/icons/Gavel";

import { PiPersonSimpleBikeBold } from "react-icons/pi";
import { BsFillPersonFill } from "react-icons/bs";
import { GrUserAdmin } from "react-icons/gr";
import { LiaAddressCardSolid } from "react-icons/lia";
import { TbReportMoney } from "react-icons/tb";
import { BiLockOpenAlt } from "react-icons/bi";
import { LuBike } from "react-icons/lu";
import { RxCrossCircled } from "react-icons/rx";
import { MdReviews } from "react-icons/md";
import { VscReferences } from "react-icons/vsc";
import { BiSolidReport } from "react-icons/bi";
import { MdTaxiAlert } from "react-icons/md";
import { HiOutlineCurrencyRupee } from "react-icons/hi";
import { AiOutlineFileText } from "react-icons/ai";
import { MdSportsMotorsports } from "react-icons/md";
import { IoMdNotifications } from "react-icons/io";
import { BiBookContent } from "react-icons/bi";
import { MdMessage } from "react-icons/md";
import { AuthContext } from "src/context/Auth";
import axios from "axios";
import { generateToken } from "src/Notifications/firebase";
import ApiConfig from "src/apiconfig/apiconfig";
import toast from "react-hot-toast";

function renderNavItems({ items, pathname, depth = 0, state, setSelectedTab }) {
  return (
    <List disablePadding>
      {items?.reduce(
        (acc, item) =>
          reduceChildRoutes({
            acc,
            item,
            pathname,
            depth,
            state,
            setSelectedTab,
          }),
        []
      )}
    </List>
  );
}

// function reduceChildRoutes({
//   acc,
//   pathname,
//   item,
//   depth,
//   state,
//   setSelectedTab,
// }) {
//   const key = item.title + depth;



//   const isActive = matchPath(pathname, {
//     path: item.href,
//     exact: false, // Set to false if you want sub-routes to be considered active
//   });


//   // if (item.items) {
//   //   const open = matchPath(pathname, {
//   //     path: item.href,
//   //     exact: false,
//   //   });

//   if (item.items) {
//     // Set 'open' to true if the current route or any sub-module is active
//     const open = isActive || item.items.some((subItem) => {
//       const subItemPath = subItem.href;
//       return matchPath(pathname, {
//         path: subItemPath,
//         exact: false, // Set to false if you want sub-routes to be considered active
//       });
//     });


//     acc.push(
//       <NavItem
//         depth={depth}
//         icon={item.icon}
//         info={item.info}
//         key={key}
//         open={Boolean(open)}
//         title={item.title}
//       >
//         {renderNavItems({
//           depth: depth + 1,
//           pathname,
//           items: item.items,
//           state,
//           setSelectedTab,
//         })}
//       </NavItem>
//     );
//   } else {
//     acc.push(
//       <NavItem
//         depth={depth}
//         href={item.href}
//         icon={item.icon}
//         info={item.info}
//         tabview={item.tabview}
//         key={key}
//         title={item.title}
//         setSelectedTab={(item) => setSelectedTab(item)}
//       />
//     );
//   }
//   return acc;
// }

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
  state,
  setSelectedTab,
}) {
  const key = item.title + depth;

  const isActive = matchPath(pathname, {
    path: item.href,
    exact: false,
  });

  if (item.items) {
    // Set 'open' to true if the current route or any sub-module is active
    const open = isActive || item.items.some((subItem) => {
      const subItemPath = subItem.href;
      return matchPath(pathname, {
        path: subItemPath,
        exact: false,
      });
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          state,
          setSelectedTab,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        tabview={item.tabview}
        key={key}
        title={item.title}
        setSelectedTab={(item) => setSelectedTab(item)}
      />
    );
  }
  return acc;
}


const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    // background: theme.palette.background.header,
    background: "#F56D21",
    position: "fixed",
  },
  desktopDrawer: {
    zIndex: "1",
    top: "76px",
    width: "250px",
    height: "calc(100% - 125px)",
    margin: "5px 10px 10px 15px",
    // background: theme.palette.background.header,
    background: "#F56D21",
    position: "fixed",
    boxShadow: "0px 0px 53px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    // marginTop: "21px",
    marginLeft: "13px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#F5C843",
    },
    "& svg": {
      color: "#F5C843",
      fontSize: "20px",
    },
  },
  btnBox: {
    position: "relative",
    left: "30%",
    bottom: "-250px",
  },
  logoutButton: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    position: "absolute",
    bottom: "5px",
    left: "17px",
    background: "#F56D21",
    fontWeight: "400",
    fontSize: "13px",
  },
  sideMenuBox: {
    // background:'#232B3B',
    "& .MuiCollapse-wrapperInner": {
      marginLeft: "45px",
    },

    "& .MuiDrawer-paperAnchorDockedLeft": {
     borderRight:"none"
    },
    // 
  },
  dialog: {
    "& .MuiDialog-paper": {
      background: "white !important",
    },
  },
  logoutimgicon: {
    border: "1px solid white", background: "white", color: "red", width: "100px", height: "70px"
  },
}));

const commonItems = [
  {
    title: "Dashboard",
    icon: AiFillDashboard,
    href: "/dashboard",
    tabview: "Arbitrage",
  },
  {
    title: "My Account",
    icon: BiLockOpenAlt,
    href: "/profile",
    tabview: "Arbitrage",
  },
];

const sections = [
  {
    items: [
      {
        title: "Dashboard",
        icon: AiFillDashboard,
        href: "/dashboard",
        tabview: "Arbitrage",
      },
      {
        title: "Rider Management",
        icon: MdSportsMotorsports,
        href: "/rider-list",
        tabview: "Arbitrage",
      },
      {
        title: "Driver Management",
        icon: AiOutlineFileText,
        href: "/driver-list",
        tabview: "Arbitrage",
      },
      {
        title: "All Rides management",
        icon: PiPersonSimpleBikeBold,
        href: "/ride-listall",
        tabview: "Arbitrage",
      },
      {
        title: "Vehicle Fee Management",
        icon: LiaAddressCardSolid,
        href: "/vehicle-manage",
        tabview: "Arbitrage",
      },
      {
        title: "Earning Report ",
        icon: TbReportMoney,
        href: "/earning-report",
        tabview: "Arbitrage",
      },
      {
        title: "Review & Ratings ",
        icon: MdReviews,
        href: "/rider-review",
        tabview: "Arbitrage",
      },
      {
        title: "Promo Code Management",
        icon: VscReferences,
        href: "/promocode-list",
        tabview: "Arbitrage",
      },
    
      // {
      //   title: "Report Management",
      //   icon: BiSolidReport,
      //   href: "/report-management",
      //   tabview: "Arbitrage",
      // },
      {
        title: "SOS Alert Management",
        icon: MdTaxiAlert,
        href: "/sos-alert",
        tabview: "Arbitrage",
      },

      {
        title: "Commissions Management",
        icon: HiOutlineCurrencyRupee,
        href: "/commission",
        tabview: "Arbitrage",
      },
      {
        title: "Notification Management",
        icon: IoMdNotifications,
        href: "/notification",
        tabview: "Arbitrage",
      },
      {
        title: "Message Management",
        icon: MdMessage,
        href: "/Chat-module",
        tabview: "Arbitrage",
      },
      {
        title: "Content Management",
        icon: BiBookContent,
        href: "/content-management",
        tabview: "Arbitrage",
      },

      // {
      //   title: "Contact & support Management",
      //   icon: BsFillPersonFill,
      //   href: "/contactSupport",
      //   tabview: "Arbitrage",
      // },
      
      
      {
        title: "My Account",
        icon: BiLockOpenAlt,
        href: "/profile",
        tabview: "Arbitrage",
      },
      {
        title: "Sub Admin Management",
        icon: GrUserAdmin,
        href: "/sub-admin",
        tabview: "Arbitrage",
      },

 


    ],
  },
];
const subAdminSection = [
  {
    items: [
      {
        title: "Dashboard",
        icon: AiFillDashboard,
        href: "/dashboard",
        tabview: "Arbitrage",
        title1:"Dashboard"
      },
      {
        title: "Riders",
        icon: MdSportsMotorsports,
        href: "/rider-list",
        tabview: "Arbitrage",
        title1:"Rider"
      },
      {
        title: "Drivers",
        icon: AiOutlineFileText,
        href: "/driver-list",
        tabview: "Arbitrage",
        title1:"Driver"
      },
      {
        title: "Rides",
        icon: PiPersonSimpleBikeBold,
        href: "/ride-listall",
        tabview: "Arbitrage",
        title1:"Rides"

      },
      {
        title: "Vehicle Fee Management",
        icon: LiaAddressCardSolid,
        href: "/vehicle-manage",
        tabview: "Arbitrage",
        title1:"VehicleTypeFeeManagement"
      },
      {
        title: "Earning Report",
        icon: TbReportMoney,
        href: "/earning-report",
        tabview: "Arbitrage",
        title1:"EarningReport"
      },
      {
        title: "Review & Ratings",
        icon: MdReviews,
        href: "/rider-review",
        tabview: "Arbitrage",
        title1:"ReviewRating"
      },
      {
        title: "Promo Code",
        icon: VscReferences,
        href: "/promocode-list",
        tabview: "Arbitrage",
        title1:"PromoCodes"
      },
      {
        title: "Report Management",
        icon: BiSolidReport,
        href: "/report-management",
        tabview: "Arbitrage",
        title1:"ReportManagement"
      },
      {
        title: "SOS Alert",
        icon: MdTaxiAlert,
        href: "/sos-alert",
        tabview: "Arbitrage",
        title1:"SOSAlert"
      },
      {
        title: "Notification",
        icon: IoMdNotifications,
        href: "/notification",
        tabview: "Arbitrage",
        title1:"Riders"
      },
      {
        title: "Message Management",
        icon: MdMessage,
        href: "/Chat-module",
        tabview: "Arbitrage",
        title1:"Riders"
      },
      {
        title: "Content Management",
        icon: BsFillPersonFill,
        href: "/content-management",
        tabview: "Arbitrage",
        title1:"StaticContentManagement"
      },
      {
        title: "My Account",
        icon: BiLockOpenAlt,
        href: "/profile",
        tabview: "Arbitrage",
        title1:"MyAccount"
      },
      {
        title: "Sub Admin",
        icon: GrUserAdmin,
        href: "/sub-admin",
        tabview: "Arbitrage",
        title1:"SubAdminmanagement"
      },
      {
        title: "Notification Management",
        icon: IoMdNotifications,
        href: "/notification",
        tabview: "Arbitrage",
        title1:"NotificationManagement"
      },
      {
        title: "Commission Management",
        icon: HiOutlineCurrencyRupee,
        href: "/commission",
        tabview: "Arbitrage",
        title1:"CommissionsManagement"
      },
     
    ],
  },
];
const sections1 = [
  {
    items: [
      {
        title: "Dashboard",
        icon: AiFillHome,
        href: "/sniper-dashboard",
        tabview: "Sniper",
      },
      {
        title: "Bot settings",
        icon: AiFillDashboard,
        href: "/bot-setting",
        tabview: "Sniper",
      },
      {
        title: "Transaction History",
        icon: FaSquarespace,
        href: "/sniper-transactions",
        tabview: "Sniper",
      },
    ],
  },
];
const NavBar = ({ onMobileClose, openMobile, tabView, setSelectedTab }) => {
  const classes = useStyles();
  const location = useLocation();
  const userDetails = useContext(AuthContext)

  console.log("dagadgajhaf",userDetails);


  const [token, setToken] = useState("");

  const fetchToken = async () => {
    const tokens = await generateToken();
    console.log(tokens, "tokentokentokentoken");
    setToken(tokens);
  };

  useEffect(() => {
    fetchToken();
  }, []);

  console.log(token, "tokentokentokentoken");



  const handleLogOut = async () => {
    try {
      const res = await axios({
        method: "PUT",
        url: ApiConfig.adminLogOut,
        data: {
          ...(token && { deviceToken: token }),
        },
        headers: {
          token: window.sessionStorage.getItem("adminToken"),
        },
      });



      if (res?.data?.responseCode === 200) {
        // setLoader(false);
        // history.goBack();
        sessionStorage.removeItem("adminToken");
        history.push("/");
        // window.location.reload();
        toast.success(res?.data?.responseMessage);
      } else if (res?.data?.responseCode === 500) {
        toast.error(res?.data?.responseMessage);
      } else {
        console.log("error in else");
        toast.error(res?.data?.responseMessage);
      }


      console.log("logOut Response",res)
    } catch (error) {
      console.log("Error in Logout", error);
    }
  };

  const permissions = userDetails && userDetails.userData ? userDetails.userData.permissions : [];

  const filteredItems = subAdminSection[0].items.filter(item =>
    permissions && typeof permissions === 'object' &&
    Object.entries(permissions).some(([key, value]) => value && item.title1 === key)
  );

  const finalFilteredItems = [...commonItems, ...filteredItems];
  const isAdmin = userDetails?.userData?.role?.includes("admin");
  const isSubAdmin = userDetails?.userData?.role?.includes("subAdmin");

  const renderedSections = isAdmin
    ? sections
    : isSubAdmin
    ? [{ items: finalFilteredItems }]
    : sections1;


  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  // const renderedSections = tabView === "Arbitrage" ? sections : sections1;
  console.table([filteredItems,permissions,renderedSections]);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box pt={2} pb={2}>
          <Box className="sideMenuBox">
            {renderedSections?.map((section, i) => (
              <List
                key={`menu${i}`}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  img: section.img,
                  items: section.items,
                  pathname: location.pathname,
                  state: section.tabView,
                  setSelectedTab,

                })}
              </List>
            ))}
          </Box>
        </Box>

        <Button
          onClick={() => setIsLogout(true)}
          className={classes.logoutButton}
        >
          <ExitToAppIcon
            style={{
              marginRight: "16px",
            }}
          />
          &nbsp; Logout
        </Button>

        {isLogout && (
          <Dialog
            maxWidth="sm"
            fullWidth
            className={classes.dialog}
            open={isLogout}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setIsLogout(false)}
          >
            <DialogContent>
              <Box className={classes.dialougTitle} align="center">
                <Box>
                  <RxCrossCircled className={classes.logoutimgicon} />
                </Box>
                <Typography variant="h4" color="primary" style={{ marginTop: "20px", color: "black" }}>LOGOUT</Typography>
                <Typography variant="h6" color="primary" style={{ marginTop: "20px", color: "black" }}>
                  Are you sure you want to logout ?
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions
              style={{
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Box mt={2} style={{ marginTop: "20px" }}>
                <Button
                  className="modelbtn"
                  color="secondary"

                  style={{ padding: "8px 21px", background: "white", border: "1px solid black" }}
                  onClick={() => setIsLogout(false)}
                >
                  No
                </Button>
                &nbsp; &nbsp;
                <Button
                  className="modelbtnyes"
                  color="secondary"

                  style={{ padding: "8px 21px", background: "white", border: "1px solid black" }}

                  onClick={handleLogOut
                  //   () => {
                  //   sessionStorage.removeItem("adminToken");
                  //   history.push("/");
                  //   window.location.reload();
                  // }
                }
                >
                  Yes
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box p={2}>{content}</Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
