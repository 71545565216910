const url = "https://node-uberbooking.mobiloitte.io"; //staging
// const url = "https://nodepune-uberbooking.mobiloitte.io";      //live
const botSok = "https://node-uberbooking.mobiloitte.io/"; //staging

// const url = "http://172.16.6.52:3055";
// const url = "http://172.16.2.38:3055";
// const url = "http://172.16.6.58:2031";
// const url = "http://172.16.6.83:2031" //izhar sir
// const url = "http://172.16.1.210:2031"; // digvijay       
// const url = "http://localhost:2031"; // 

const ApiConfig = {
  botSok,
  url,
  adminLogin: `${url}/adminLogin`,
  verifyOTPAdmin: `${url}/verifyOTPAdmin`,
  forgotPassword: `${url}/forgotPassword`,
  resetPassword: `${url}/resetPassword`,
  changePassword: `${url}/changePassword`,
  resendOTPAdmin: `${url}/resendOTPAdmin`,
  // profile: `${url}/api/v1/user/profile`,
  getProfile: `${url}/getAdminProfile`,
  uploadFile: `${url}/uploadFile`,
  // editProfile: `${url}/api/v1/user/editProfile`,
  adminEditProfile: `${url}/updateAdminDetails`,
  uploadImage: `${url}/api/v1/upload/uploadImage`,

  // Dashboard
  adminDashboard: `${url}/adminDashboard`,

  // Riders Management
  getRiderAndDriverListing: `${url}/getRiderAndDriverListing`,

  //Driver and Rider
  riderAndDriverDetails: `${url}/riderAndDriverDetails`,
  getDriverNewRequest: `${url}/getDriverNewRequest`,
  approveDriveByAdmin: `${url}/approveDriveByAdmin`,
  blockRiderAndDriver: `${url}/blockRiderAndDriver`,
  AddDriverByAdmin: `${url}/AddDriverByAdmin`,

  // Notification Management
  getAllNotification: `${url}/getAllNotification`,
  createAnnouncementToDriver: `${url}/createAnnouncementToDriver`,
  editAnnouncementToDriver: `${url}/editAnnouncementToDriver`,
  listAnnouncement: `${url}/listAnnouncement`,

  // Message Management
  getAllChatRooms: `${url}/getAllChatRooms`,

  //static Content
  fetchStaticContent: `${url}/fetchStaticContent`,
  createFaq: `${url}/addFAQ`,
  getFaq: `${url}/FAQ`,
  deleteFaq: `${url}/deleteFAQ`,
  editFeq: `${url}/editFAQ`,
  editStaticContent: `${url}/editStaticContent`,

  // Rides List
  ridesList: `${url}/ridesList`,
  viewRideDetails: `${url}/viewRideDetails`,
  ridesView: `${url}/ridesView`,

  // promo code
  listpromocode: `${url}/listpromocode`,
  addPromoCode: `${url}/addUpdatePromoCode`,
  deletePromocode: `${url}/deletePromocode`,
  activeBlockpromocode: `${url}/activeBlockpromocode`,

  // Report Management
  reportListing: `${url}/reportListing`,
  viewReport: `${url}/viewReport`,
  replyOnReport: `${url}/replyOnReport`,

  // Earning Report
  adminEarningReports: `${url}/adminEarningReports`,

  // vehicle fee management
  editVehicleFee: `${url}/editVehicleFee`,
  vehicleTypeAndFeeListing: `${url}/vehicleTypeAndFeeListing`,
  addVehicleType: `${url}/addUpdateVehicleType`,
  deleteVehicleType: `${url}/deleteVehicleType`,

  // Review and Rating
  viewRatingByDriver: `${url}/viewRatingByDriver`,
  viewRatingByRider: `${url}/viewRatingByRider`,
  deleteRiderReviewByAdmin: `${url}/deleteRiderReviewByAdmin`,
  deleteDriverReviewByAdmin: `${url}/deleteDriverReviewByAdmin`,

  // SOS alert
  getAllSOS: `${url}/getAllSOS`,

  acceptEmailRequest: `${url}/api/v1/admin/acceptEmailRequest`,
  rejectEmailRequest: `${url}/api/v1/admin/rejectEmailRequest`,
  listAllEmailRequest: `${url}/api/v1/admin/listAllEmailRequest`,
  viewemailreqDetail: `${url}/api/v1/admin/viewEmailReqOfParticularUser`,
  acceptKycRequest: `${url}/api/v1/kyc/acceptKycRequest`,
  rejectKycRequest: `${url}/api/v1/kyc/rejectKycRequest`,
  kyclist: `${url}/api/v1/kyc/listAllKycRequest`,
  viewKyc: `${url}/api/v1/kyc/viewKycDetail`,
  approveOrReject: `${url}/api/v1/admin/kyc/approveOrReject`,
  listUser: `${url}/api/v1/admin/listUser`,
  viewUser: `${url}/api/v1/admin/viewUser`,
  createPlan: `${url}/api/v1/plan/create`,
  editPlan: `${url}/api/v1/plan/edit`,
  viewPlan: `${url}/api/v1/plan/view`,
  Planlist: `${url}/api/v1/plan/list`,
  blockUnblockUser: `${url}/api/v1/admin/blockUnblockUser`,
  delete: `${url}/api/v1/plan/delete/`,

  claimRewards: `${url}/api/v1/admin/getWithdrawalList`,
  viewClaimRewards: `${url}/api/v1/admin/viewWithdrawalList`,
  acceptClaimRequest: `${url}/api/v1/admin/adminApproveWithdrawal`,
  rejectClaimRequest: `${url}/api/v1/admin/adminApproveWithdrawal`,

  staticData: `${url}/api/v1/static/static`,
  staticDatavIew: `${url}/api/v1/static/static`,

  listReports: `${url}/api/v1/admin/getUsersTotalData`,

  //SubAdmin By Arun pratap singh
  subAdminListing: `${url}/subAdminListing`,
  addSubAdmin: `${url}/addSubAdmin`,
  editSubAdmin: `${url}/editSubAdmin`,
  deleteSubAdmin: `${url}/deleteSubAdmin`,
  blockUnblockSubAdmin: `${url}/blockUnblockSubAdmin`,
  viewSubAdmin: `${url}/viewSubAdmin`,


  //BankAccount APIs By Arun pratap singh
  addUpdateAccountDetails: `${url}/addUpdateBankAccountDetails`,
  getAccountDetails: `${url}/listBankAccountDetails`,
  deleteAccountDetails: `${url}/deleteAccount`,

  //commission
  getCommisionPaymentRequest: `${url}/getCommisionPaymentRequest`,
  approveRejectPaymentRequest: `${url}/approveRejectPaymentRequest`,
  getAllCommissionData: `${url}/getAllCommissionData`,

  //Chat Feedback Apis
  userFeedback: `${url}/userFeedback`,

  //rating
  getAllRatingAndReview : `${url}/getAllRatingAndReview`,
  deleteRatingAndReview : `${url}/deleteRatingAndReview`,

  getVehicleTypeAndVehicleStandard:`${url}/getVehicleTypeAndVehicleStandard`,

  //logout
  adminLogOut: `${url}/adminLogOut`,


  getRiderAndDriverListingWithoutPagination:`${url}/getRiderAndDriverListingWithoutPagination`,

  getDriverUpdateRequest:`${url}/getDriverUpdateRequest`,

  updateDriverDocsRequestByAdmin:`${url}/updateDriverDocsRequestByAdmin`
};

export default ApiConfig;
